import React from 'react'

const GoogleTagManagerNoScript = () => (
  <noscript>
    <iframe
      src="https://load.gtm.josephcentergj.com/ns.html?id=GTM-WMZWQGR3"
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
      title="Google Tag Manager - No Script"
    />
  </noscript>
)

export default GoogleTagManagerNoScript
