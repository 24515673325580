import { graphql, Link, useStaticQuery } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import React, { useEffect, useRef, useState } from 'react'
import ReactFocusLock from 'react-focus-lock'
import styled from 'styled-components'
import Hamburger from './Hamburger'
import Menu from './Menu'

const Nav = styled.header`
  width: 100vw;
  height: var(--nav-height);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  color: var(--white);
  z-index: 1000;
  .inline {
    display: inline-flex;
  }
  .flex {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;
  }
  .center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  nav ul {
    list-style-type: none;
    padding: 0;
    li[aria-current='page'] {
      border: 1.5px solid var(--white);
    }
    a {
      padding: 1.1rem;
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 450px) {
    height: var(--nav-height-mobile);
  }
`

const NavContainer = styled.div`
  --lg-circle: 26rem;
  --sm-circle: 20rem;

  width: 100%;
  position: relative;
  z-index: 950;
`

const Logo = styled(Link)`
  position: absolute;
  top: calc((var(--lg-circle) - var(--lg-circle)) - 7rem);
  left: calc((var(--lg-circle) - var(--lg-circle)) - 7rem);
`

const GoldCircle = styled.div`
  width: var(--lg-circle);
  height: var(--lg-circle);
  background-color: var(--gold);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 450px) {
    width: var(--sm-circle);
    height: var(--sm-circle);
  }
`

const DarkGreenCircle = styled.div`
  width: calc(var(--lg-circle) - 4rem);
  height: calc(var(--lg-circle) - 4rem);
  background-color: var(--darkgreen);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 450px) {
    width: calc(var(--sm-circle) - 4rem);
    height: calc(var(--sm-circle) - 4rem);
  }
`

const GreenCircle = styled.div`
  width: calc(var(--lg-circle) - 8rem);
  height: calc(var(--lg-circle) - 8rem);
  background-color: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: calc(var(--lg-circle) - 12rem);
    height: calc(var(--lg-circle) - 12rem);
    margin: 2rem 0 0 2rem;
    @media only screen and (max-width: 450px) {
      width: calc(var(--sm-circle) - 12rem);
      height: calc(var(--sm-circle) - 12rem);
    }
  }
  @media only screen and (max-width: 450px) {
    width: calc(var(--sm-circle) - 8rem);
    height: calc(var(--sm-circle) - 8rem);
  }
`

const NavLinks = styled.div`
  height: calc(var(--nav-height) / 1.5);
  margin-right: 5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  align-items: center;
  gap: 0 2rem;
  .hide-sm {
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }
  @media only screen and (max-width: 500px) {
    margin-right: 2rem;
  }
  @media only screen and (max-width: 450px) {
    height: calc(var(--nav-height-mobile) / 1.5);
  }
`

const VolPage = styled(Link)`
  color: var(--white);
  border: 0.2rem var(--white) solid;
  padding: 0.5rem 1.2rem;
  text-decoration: none;
  background-color: var(--gold);
  outline: 0.1rem solid var(--gold);
  &:hover {
    border: 0.2rem var(--gold) solid;
    box-shadow: none;
  }
`

const GiveOptions = styled.div`
  position: relative;
`

const Give = styled.button`
  // reset styles
  background-color: transparent;
  text-shadow: none;
  box-shadow: none;
  line-height: 1;
  margin: 0;
  padding: 0;

  // new styles
  color: var(--white);
  border: 0.2rem var(--white) solid;
  padding: 0.5rem 1.2rem;
  outline: 0.1rem solid var(--gold);
  background-color: var(--gold);
  color: var(--white);
  &:hover {
    color: var(--white);
    background-color: var(--gold);
    border: 0.2rem var(--gold) solid;
    box-shadow: none;
  }
`

const GiveDropdown = styled.div`
  min-width: 20rem;
  margin-top: 2rem;
  margin-left: -6rem;
  padding: 2rem 1rem;
  position: absolute;
  background: var(--white);
  border: 0.1rem solid gray;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.5rem var(--black);
`

const List = styled.ul`
  width: 18rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 0;
  list-style: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  width: 100%;
  display: flex;
  a {
    width: 100%;
    padding: 0.6rem;
    text-align: center;
    text-decoration: none;
    border: 0.2rem solid var(--gold);
    color: var(--gold);
    &:hover {
      background-color: var(--gold);
      color: var(--white);
      cursor: pointer;
    }
  }
`

export default function Navigation() {
  const [dropdownState, setDropdownState] = useState({ open: false })

  const { navitems } = useStaticQuery(graphql`
    query {
      navitems: allSanityNavigation {
        nodes {
          id
          mainalt
          title
          logo {
            asset {
              id
            }
            ...ImageWithPreview
          }
        }
      }
    }
  `)

  const { nodes } = navitems

  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
  }
  const node = useRef()
  const menuId = 'main-menu'

  // Dropdown state management
  const handleDropdownClick = () =>
    setDropdownState({ open: !dropdownState.open })

  // Close on Click outside Give dropdown
  const container = useRef()

  const handleClickOutside = e => {
    if (container.current && !container.current.contains(e.target)) {
      setDropdownState({ open: false })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  return (
    <Nav>
      {nodes.map(n => (
        <NavContainer className="flex navBG full" key={n.id}>
          <Logo to="/" id="logo">
            <GoldCircle>
              <DarkGreenCircle>
                <GreenCircle>
                  <SanityImage
                    {...n.logo}
                    alt={n.mainalt}
                    style={{
                      objectFit: 'contain',
                      auto: 'format',
                    }}
                  />
                </GreenCircle>
              </DarkGreenCircle>
            </GoldCircle>
          </Logo>
          <NavLinks ref={node}>
            <VolPage to="/forms/volunteer" className="hide-sm">
              Volunteer
            </VolPage>
            <GiveOptions className="hide-sm" ref={container}>
              <Give type="button" onClick={handleDropdownClick}>
                Give
              </Give>
              {dropdownState.open && (
                <GiveDropdown>
                  <List>
                    <Item>
                      <Link to="/donate" rel="noreferrer noopener">
                        One Time
                      </Link>
                    </Item>
                    <Item>
                      <Link to="/partnership" rel="noreferrer noopener">
                        Partner with Us
                      </Link>
                    </Item>
                  </List>
                </GiveDropdown>
              )}
            </GiveOptions>
            <ReactFocusLock disabled={!open}>
              <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
              <Menu
                open={open}
                setOpen={setOpen}
                closeMenu={closeMenu}
                id={menuId}
              />
            </ReactFocusLock>
          </NavLinks>
        </NavContainer>
      ))}
    </Nav>
  )
}
