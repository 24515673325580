import styled from 'styled-components'

export const StyledBurger = styled.button`
  // reset styles
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }

  width: 4rem;
  height: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.7rem 0;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:hover {
    background: none;
    span {
      box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
    }
  }

  span {
    width: 4rem;
    height: 0.5rem;
    background: var(--gold);
    outline: 0.2rem solid var(--white);
    border-radius: 1rem;
    transition: all 0.1s linear;
    transform-origin: 2rem;
    position: ${({ open }) => (open ? 'fixed' : 'relative')};

    :first-child {
      background: ${({ open }) => (open ? 'var(--white)' : 'var(--gold)')};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      background: ${({ open }) => (open ? 'var(--white)' : 'var(--gold)')};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
    @media only screen and (max-width: 770px) {
      right: ${({ open }) => (open ? '2rem' : '0')};
    }
    @media only screen and (max-width: 500px) {
      right: ${({ open }) => (open ? '1rem' : '0')};
    }
  }
`
