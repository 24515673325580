import React, { useState } from 'react'
import styled from 'styled-components'
import 'normalize.css'
import { graphql, useStaticQuery } from 'gatsby'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import Footer from './Footer'
import Banner from './Banner'
import GoogleTagManagerNoScript from './analytics/GoogleTagManagerNoScript'
import Navigation from './Navigation'

const ContentStyles = styled.div`
  // set essentials for footer to stay at bottom
  min-height: 100dvh;
  position: relative;
  margin: 0 auto 2rem;
  background: var(--white);
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    .storyImg {
      margin: 0;
    }
  }
  @media only screen and (max-width: 510px) {
    padding-bottom: 12rem;
  }
`

const PagesContainer = styled.main`
  --content-margin: 4rem;
  --mobile-margin: 1rem;

  #generic-pages {
    margin-top: var(--nav-height);
    @media only screen and (max-width: 450px) {
      margin-top: var(--nav-height-mobile);
    }
  }
  #hero-image-pages {
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: 80dvh;
    }
    #header-1 {
      margin: -10rem var(--content-margin) 0;
      background: var(--white);
      position: relative;
      border-radius: 2rem;
      @media only screen and (max-width: 900px) {
        margin: -10rem var(--content-margin) 0;
      }
      @media only screen and (max-width: 450px) {
        margin: -10rem var(--mobile-margin) 0;
      }
    }
    @media only screen and (max-width: 700px) {
      #story-pg-img {
        height: 100dvh;
      }
    }
  }
`

const BannerParser = styled.div``

// eslint-disable-next-line
export default function Layout({ children }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { banner } = useStaticQuery(graphql`
    query {
      banner: allSanityBanner {
        nodes {
          id
          _rawMessage
        }
      }
    }
  `)

  const bannernodes = banner.nodes

  return (
    <>
      <GoogleTagManagerNoScript />
      <GlobalStyles menuOpen={menuOpen} />
      <Typography />
      <ContentStyles tabIndex="-1">
        {bannernodes.map(node => (
          <BannerParser key={node.id}>
            {node.id ? <Banner /> : <div />}
          </BannerParser>
        ))}
        <Navigation open={menuOpen} setOpen={setMenuOpen} />
        <PagesContainer>{children}</PagesContainer>
      </ContentStyles>
      <Footer />
    </>
  )
}
