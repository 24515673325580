import { Link } from 'gatsby'
import styled from 'styled-components'

export const StyledMenu = styled.nav`
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: var(--green);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: auto;
  transition: transform 0.1s ease-in-out;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: left;
    color: var(--white);
    line-height: 2;
    pointer: cursor;
    letter-spacing: 0.4rem;

    &:hover {
      text-shadow: 0 0 1rem var(--white);
    }
  }
`

// Ensure space between top nav and list of links
export const NavTopSpace = styled.div`
  position: relative;
  padding-top: calc(var(--nav-height) * 0.5);
  @media only screen and (max-width: 450px) {
    padding-top: calc(var(--nav-height-mobile) * 0.5);
  }
`

export const LinkContainer = styled.ul`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  button {
    // reset styles
    background: none;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    box-shadow: none;
    padding: 0;

    // new styles
    width: 100%;
    padding: 0 2rem 0 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.4rem;

    &:hover {
      // reset hover styles
      background: none;
      border: none;
      font-family: inherit;
      font-weight: inherit;
      box-shadow: none;

      // new styles
      color: var(--white);
      text-shadow: 0 0 1rem var(--white);
    }
    @media only screen and (max-width: 325px) {
      letter-spacing: 0;
    }
  }
`

export const PageContainer = styled.div`
  padding-bottom: 1rem;
`

export const Links = styled.div`
  a {
    @media only screen and (max-width: 325px) {
      letter-spacing: 0;
    }
  }
`

export const MenuItem = styled.li``

export const HR = styled.hr`
  border: 0.2rem solid var(--white);
`

export const AboutBtn = styled.button`
  svg {
    transition: transform 0.1s ease-in-out;
  }
`

export const ProgramsBtn = styled.button`
  svg {
    transition: transform 0.1s ease-in-out;
  }
`

export const FormsBtn = styled.button`
  svg {
    transition: transform 0.1s ease-in-out;
  }
`

export const GiveBtn = styled.button`
  svg {
    transition: transform 0.1s ease-in-out;
  }
`

// Top Section of open Menu
export const NavContainer = styled.div`
  width: 100%;
  height: var(--nav-height);
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  @media only screen and (max-width: 450px) {
    height: var(--nav-height-mobile);
  }
`

export const Logo = styled(Link)`
  width: var(--nav-height);
  img {
    width: calc(var(--nav-height) * 0.75);
    @media only screen and (max-width: 450px) {
      width: calc(var(--nav-height-mobile) * 0.75);
    }
  }
  @media only screen and (max-width: 450px) {
    width: var(--nav-height-mobile);
  }
`

export const TopLinks = styled.div`
  height: 3.5rem;
  position: absolute;
  top: 5rem;
  right: 12rem;
  display: inline-flex;
  align-self: center;
  gap: 0 2rem;
  @media only screen and (max-width: 770px) {
    right: 8rem;
  }
  @media only screen and (max-width: 450px) {
    display: none;
  }
`

export const VolPage = styled(Link)`
  // reset styles
  padding: 0;
  text-transform: none !important;
  line-height: 1 !important;

  // new styles
  border: 0.2rem var(--white) solid;
  padding: 0.5rem 1.2rem;
  font-size: 2rem !important;
  letter-spacing: 0.1rem !important;
  &:hover {
    background-color: var(--gold);
  }
`

export const GiveOptions = styled.div`
  position: relative;
`

export const Give = styled.button`
  // reset styles
  background-color: transparent;
  text-shadow: none;
  box-shadow: none;
  line-height: 1;
  margin: 0;
  padding: 0;

  // new styles
  height: 100%;
  color: var(--white);
  border: 0.2rem var(--white) solid;
  padding: 0.5rem 1.2rem;
  &:hover {
    background-color: var(--gold);
    color: var(--white);
  }
`

export const GiveDropdown = styled.div`
  a,
  button {
    // reset styles
    padding: 0;
    letter-spacing: 0.1rem !important;
    text-transform: none !important;
    line-height: 1 !important;
    &:hover {
      transition: none;
      text-shadow: none;
      background: var(--gold);
      color: var(--white);
    }
  }

  // new styles
  border: 0.2rem var(--white) solid;
  padding: 0.5rem 1.2rem;
  font-size: 2rem !important;
  letter-spacing: 0.1rem !important;

  min-width: 20rem;
  margin-top: 2rem;
  margin-left: -6rem;
  padding: 2rem 1rem;
  position: absolute;
  background: var(--white);
  border: 0.1rem solid gray;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.5rem var(--black);
  transition: all 0.1s ease-in-out;
  z-index: 1050;
`

export const List = styled.ul`
  width: 18rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 0;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  width: 100%;
  display: flex;
  a {
    width: 100%;
    padding: 0.6rem;
    text-align: center;
    text-decoration: none;
    border: 0.2rem solid var(--gold);
    color: var(--gold);
    &:hover {
      background-color: var(--gold);
      color: var(--white);
      cursor: pointer;
    }
  }
`

export const ExtLink = styled.a``
