import React from 'react'
import Layout from './src/components/Layout'
import { ChristmasProvider } from './src/components/ChristmasContext'
import { CollectionProvider } from './src/components/CollectionContext'

export function wrapPageElement({ element, props }) {
  return <Layout {...props}> {element}</Layout>
}

export function wrapRootElement({ element }) {
  return (
    <CollectionProvider>
      <ChristmasProvider>{element}</ChristmasProvider>
    </CollectionProvider>
  )
}
