import React, { useEffect, useRef, useState } from 'react'
import { bool } from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FaPlus } from 'react-icons/fa'
import SanityImage from 'gatsby-plugin-sanity-image'
import {
  AboutBtn,
  ExtLink,
  FormsBtn,
  Give,
  GiveBtn,
  GiveDropdown,
  GiveOptions,
  HR,
  Item,
  LinkContainer,
  Links,
  List,
  Logo,
  MenuItem,
  NavContainer,
  NavTopSpace,
  PageContainer,
  ProgramsBtn,
  StyledMenu,
  TopLinks,
  VolPage,
} from './Menu.styled'

// eslint-disable-next-line
const Menu = ({ open, closeMenu, ...props }) => {
  // state management
  const [dropdownState, setDropdownState] = useState({ open: false })
  const [aboutState, setAboutState] = useState({ open: false })
  const [programsState, setProgramsState] = useState({ open: false })
  const [formsState, setFormsState] = useState({ open: false })
  const [giveState, setGiveState] = useState({ open: false })

  const { navitems } = useStaticQuery(graphql`
    query {
      navitems: allSanityNavigation {
        nodes {
          id
          pagelinks {
            _key
            name
            link
          }
          programlinks {
            _key
            name
            link
          }
          formlinks {
            _key
            name
            link
          }
          aboutlinks {
            _key
            name
            link
          }
          givelinks {
            _key
            name
            link
          }

          logo {
            asset {
              id
            }
            ...ImageWithPreview
          }
        }
      }
    }
  `)

  const { nodes } = navitems
  const isHidden = !!open
  const tabIndex = isHidden ? 0 : -1

  // Close on Click outside Give dropdown
  const container = useRef()

  const handleClickOutside = e => {
    if (container.current && !container.current.contains(e.target)) {
      setDropdownState({ open: false })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // Dropdown state management
  const handleDropdownClick = () =>
    setDropdownState({ open: !dropdownState.open })

  const handleAboutClick = () => setAboutState({ open: !aboutState.open })

  const handleProgramsClick = () =>
    setProgramsState({ open: !programsState.open })

  const handleFormsClick = () => setFormsState({ open: !formsState.open })

  const handleGiveClick = () => setGiveState({ open: !giveState.open })

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {nodes.map(node => (
        <NavContainer className="flex navBG full" key={node.id}>
          <Logo to="/" id="logo" tabIndex={tabIndex}>
            <SanityImage
              {...node.logo}
              alt={node.mainalt}
              style={{
                objectFit: 'contain',
                auto: 'format',
              }}
            />
          </Logo>
          <TopLinks>
            <VolPage
              to="/forms/volunteer"
              tabIndex={tabIndex}
              onClick={closeMenu}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  closeMenu()
                }
              }}
            >
              Volunteer
            </VolPage>
            <GiveOptions ref={container}>
              <Give
                type="button"
                tabIndex={tabIndex}
                onClick={handleDropdownClick}
              >
                Give
              </Give>
              {dropdownState.open && (
                <GiveDropdown>
                  <List>
                    <Item>
                      <ExtLink href="#" rel="noreferrer noopener">
                        One Time
                      </ExtLink>
                    </Item>
                    <Item>
                      <Link
                        to="/partnership"
                        rel="noreferrer noopener"
                        onClick={closeMenu}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            closeMenu()
                          }
                        }}
                      >
                        Partner with Us
                      </Link>
                    </Item>
                  </List>
                </GiveDropdown>
              )}
            </GiveOptions>
          </TopLinks>
        </NavContainer>
      ))}
      <NavTopSpace>
        {nodes.map(node => (
          <LinkContainer key={node.id}>
            <MenuItem>
              <Link
                to="/"
                tabIndex={tabIndex}
                onClick={closeMenu}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    closeMenu()
                  }
                }}
              >
                Home
              </Link>
            </MenuItem>
            <HR />
            <AboutBtn tabIndex={tabIndex} onClick={handleAboutClick}>
              About{' '}
              <FaPlus
                style={{
                  transform: aboutState.open ? 'rotate(45deg) ' : 'rotate(0)',
                }}
              />
            </AboutBtn>
            <HR />
            <PageContainer>
              {aboutState.open &&
                node.aboutlinks.map(page => (
                  <Links key={page._key}>
                    <MenuItem>
                      <Link
                        to={page.link}
                        tabIndex={tabIndex}
                        onClick={closeMenu}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            closeMenu()
                          }
                        }}
                      >
                        {page.name}
                      </Link>
                    </MenuItem>
                  </Links>
                ))}
            </PageContainer>
            <ProgramsBtn
              open={open}
              tabIndex={tabIndex}
              onClick={handleProgramsClick}
            >
              Programs{' '}
              <FaPlus
                style={{
                  transform: programsState.open
                    ? 'rotate(45deg) '
                    : 'rotate(0)',
                }}
              />
            </ProgramsBtn>
            <HR />
            <PageContainer>
              {programsState.open &&
                node.programlinks.map(page => (
                  <Links key={page._key}>
                    <MenuItem>
                      <Link
                        to={page.link}
                        onClick={closeMenu}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            closeMenu()
                          }
                        }}
                        tabIndex={tabIndex}
                      >
                        {page.name}
                      </Link>
                    </MenuItem>
                  </Links>
                ))}
            </PageContainer>
            <FormsBtn tabIndex={tabIndex} onClick={handleFormsClick}>
              Forms{' '}
              <FaPlus
                style={{
                  transform: formsState.open ? 'rotate(45deg) ' : 'rotate(0)',
                }}
              />
            </FormsBtn>
            <HR />
            <PageContainer>
              {formsState.open &&
                node.formlinks.map(page => (
                  <Links key={page._key}>
                    <MenuItem>
                      <Link
                        to={page.link}
                        onClick={closeMenu}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            closeMenu()
                          }
                        }}
                        tabIndex={tabIndex}
                      >
                        {page.name}
                      </Link>
                    </MenuItem>
                  </Links>
                ))}
            </PageContainer>

            {node.pagelinks.map(page => (
              <Links key={page._key}>
                <MenuItem>
                  <Link
                    to={page.link}
                    onClick={closeMenu}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        closeMenu()
                      }
                    }}
                    tabIndex={tabIndex}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
                <HR />
              </Links>
            ))}
            {/* Add Giving Option links here */}
            <GiveBtn tabIndex={tabIndex} onClick={handleGiveClick}>
              Partner With Us{' '}
              <FaPlus
                style={{
                  transform: giveState.open ? 'rotate(45deg) ' : 'rotate(0)',
                }}
              />
            </GiveBtn>
            <HR />
            <PageContainer>
              {giveState.open &&
                node.givelinks.map(page => (
                  <Links key={page._key}>
                    <MenuItem>
                      <Link
                        to={page.link}
                        onClick={closeMenu}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            closeMenu()
                          }
                        }}
                        tabIndex={tabIndex}
                      >
                        {page.name}
                      </Link>
                    </MenuItem>
                  </Links>
                ))}
            </PageContainer>
          </LinkContainer>
        ))}
      </NavTopSpace>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
