import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
/* CSS Reset by Mirkov Sasa https://github.com/mirkovsasa/CSS-Reset/blob/main/Reset.css */

/* Resetting defaults */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}

/* Setting border box model for easier sizing of elements */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Setting font size to make 10px = 1rem for easier scaling and manipulation of all elements in rem's */
html {
    font-size: 62.5%;
}

/* Setting default distance between the lines */
body {
    line-height: 1;
}

/* inheriting text for consistency */
input, button, textarea, select {
  font: inherit;
}

/* Removing default styles for lists */    
ol, ul {
    list-style: none;
}

/* Removing defaults styles for quotes */    
blockquote, q {
    quotes: none;
}
    
blockquote:before, blockquote:after,
    q:before, q:after {
    content: '';
    content: none;
}
    
/* Removing default outline styles for elements */
:is(a, button, input, textarea, summary) {
  --outline-size: max(2px, 0.08em);
  --outline-style: solid;
  --outline-color: blue;
  --outline-offset: 2px;
}

:is(a, button, input, textarea, summary):focus {
  outline: var(--outline-size) var(--outline-style) var(--outline-color);
  outline-offset: var(--outline-offset, var(--outline-size));
}

:is(a, button, input, textarea, summary):focus-visible {
  outline: var(--outline-size) var(--outline-style) var(--outline-color);
  outline-offset: var(--outline-offset, var(--outline-size));
}

:is(a, button, input, textarea, summary):focus:not(:focus-visible) {
  outline: none;
}

#burger:focus {
  width: 3.8rem;
  height: 3.5rem;
}
    
/* Removing table defaults */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Changing default img properties for easier workflow */
img, picture {
    max-width: 100%;
    display: block;
}
/* End Reset */

  :root {
    // Color Scheme
    --white: #fff;
    --black: #2E2E2E;
    --grey: #efefef;
    --red: #FF4949;
    --blue: #4682b4;
    --yellow: rgba(251,248,146,1);
    --brown: rgba(114,103,82,1);
    --gold: rgba(202,162,48,1);
    --darkgreen: rgba(29,95,85,1);
    --green: rgba(96,181,103,1);
    --opaque-green: rgba(96,181,103,0.75);

    // Measurements
    --nav-height: 20rem;
    --nav-height-mobile: 14rem;
  }

  html {
    background-color: var(--white);
    font-size: 10px;
    overflow: ${props => (props.menuOpen ? 'hidden' : 'auto')};
  }
  
  body {
    font-size: 2rem;
    overflow-x: hidden;
    overflow: ${props => (props.menuOpen ? 'hidden' : 'auto')};
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    --cast: 2px;
    background: var(--blue);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 2px 5px 10px gray;
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    span {
      vertical-align: middle;
    }
    &:hover {
      box-shadow: 0 0 10px gray;
      background-color: lightsteelblue;
      color: #36454f;
    }
  }
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--darkgreen) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 1px solid black;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }
  .carousel .thumb {
    margin-right: 0px;
  }
  .carousel .thumbs {
    display: inline-flex;
    align-items: center;
  }
  .thumbs img {
    max-height: 70px;
  }
  .carousel button.control-arrow {
    display: flex;
    background-color: #333;
  }
`

export default GlobalStyles
