import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Create a order context
const ChristmasContext = React.createContext()

export function ChristmasProvider({ children }) {
  // we need to stick state in here
  const [formSubmit, setFormSubmit] = useState([])
  return (
    <ChristmasContext.Provider value={(formSubmit, setFormSubmit)}>
      {children}
    </ChristmasContext.Provider>
  )
}

ChristmasProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default ChristmasContext
