import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

// Create a order context
const CollectionContext = createContext()

export function CollectionProvider({ children }) {
  const [formSubmit] = useState([])
  return (
    <CollectionContext.Provider value={formSubmit}>
      {children}
    </CollectionContext.Provider>
  )
}

CollectionProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default CollectionContext
