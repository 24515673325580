import { PortableText, defaultComponents } from '@portabletext/react'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'

const BannerStyles = styled.div`
  background-color: tomato;
  width: 33vw;
  height: 15rem;
  margin: 75px 0 0 0;
  padding: 5rem 1rem;
  z-index: 1;
  position: fixed;
  top: 10dvh;
  right: 0;
  display: grid;
  grid-template-columns: minmax(auto, 1fr);
  place-content: center;
  .message {
    padding: 0 1rem;
    font-size: 1.7rem;
    text-align: center;
    color: #fff;
    text-transform: smallcaps;
  }
  .message a {
    color: #fff;
    text-decoration-color: #fff;
  }
  .message a:hover {
    color: var(--green);
    text-decoration-color: var(--green);
  }
  @media only screen and (max-width: 500px) {
    width: 50vw;
    top: 25dvh;
  }
  @media only screen and (max-width: 500px) {
    width: 75vw;
  }
`

const CloseButton = styled.button`
  box-shadow: none;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2.5rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
`

export default function Banner() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: allSanityBanner {
        nodes {
          id
          _rawMessage
        }
      }
    }
  `)
  const { nodes } = banner

  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible) return null

  return (
    <BannerStyles>
      <CloseButton
        className="close-button"
        type="button"
        onClick={() => setIsVisible(false)}
      >
        &times;
      </CloseButton>
      {nodes.map(n => (
        <div className="message" key={n.id}>
          <PortableText value={n._rawMessage} components={defaultComponents} />
        </div>
      ))}
    </BannerStyles>
  )
}
